@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.grecaptcha-badge{
  bottom: -13px !important;
}

.content {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow-x: hidden;
}

.app {
  display: flex;
  /* Two columns, first one auto-sized (fit content), second one takes the remaining space */
  position: relative;
  height: auto;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}